import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Header = ({ children }) => (
  <header className={styles.container}>{children}</header>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
