/**
 * File contains all localization support configuration
 * ====================================================
 * i18next -- https://www.i18next.com/
 * react-i18next -- https://react.i18next.com/
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Call Assistant': 'Call Assistant',
      'Powered by': 'Powered by',
      Confirm: 'Confirm',
      Confirmed: 'Confirmed',
      'Oops,': 'Oops,',
      'Something went wrong !': 'Something went wrong !',
      "The page you're looking for no longer exists.":
        "The page you're looking for no longer exists.",
      'Back to home': 'Back to home',
      'Read more': 'Read more',
      'Something went wrong': 'Something went wrong',
      'Call me ASAP': 'Call me ASAP',
      'Call me in 30 min': 'Call me in 30 min',
      'Call me in 1 hour': 'Call me in 1 hour',
      'When to call back': 'When to call back',
      'Pick a Time': 'Pick a Time',
      Today: 'Today',
      Tomorrow: 'Tomorrow',
      'Select When': 'Select When',
      'Select Time': 'Select Time',
      'Schedule Call back': 'Schedule Call back',
      '24 hr': '24 hr',
      'Caller ID & Contacts': 'Caller ID & Contacts',
      'Get the Sync.me app for your phone':
        'Get the Sync.me app for your phone',
      'Try for Free': 'Try for Free',
      'Try It Now!': 'Try It Now!',
      'Network Error': 'Network Error',
      'No connect': 'No connect',
      'Oops, something wrong.': 'Oops, something wrong.',
      'Please try again later.': 'Please try again later.',
      'Close': 'Close'
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
