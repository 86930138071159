import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const COLORS = {
  primary: '#ff6969',
  dark: '#55565a',
  light: '#8b90a3',
  white: '#fff',
};

export const ICON_NAMES = {
  'arrow-left': 'arrow-left',
  globe: 'globe',
  calendar: 'calendar',
  user: 'user',
  clock: 'clock',
  location: 'location',
  checkmark: 'checkmark',
  'arrow-small-right': 'arrow-small-right',
  'arrow-small-left': 'arrow-small-left',
  description: 'description',
  search: 'search',
};

/**
 * Renders icon depending on given name
 * @param {string} name icon name
 * @param {string} color optional icon color
 * @returns {JSX.Element}
 */
export function renderIcon(name, color, size, width) {
  switch (name) {
    case 'search':
      return (
        <svg
          data-test="search"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <path
            id="ic_search_24px"
            d="M13.006 11.805h-.632l-.224-.216a5.211 5.211 0 1 0-.56.56l.216.224v.632l4 3.994L17 15.807zm-4.8 0a3.6 3.6 0 1 1 3.6-3.6 3.6 3.6 0 0 1-3.606 3.6z"
            fill={COLORS[color]}
            transform="translate(-3 -3)"
          />
        </svg>
      );

    case 'arrow-left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={14.605 * size}
          height={16.801 * size}
          fill="none"
          viewBox="0 0 14.605 16.801"
          data-test="arrow-left"
        >
          <g id="back" transform="translate(.243 -8.246)">
            <path
              id="Path_22"
              d="M7.711 24.348a.68.68 0 1 0 .962-.962L2.65 17.363h10.531a.68.68 0 1 0 0-1.36H2.65l6.023-6.024a.68.68 0 0 0-.962-.962L.527 16.2a.68.68 0 0 0 0 .962z"
              fill={COLORS[color]}
              data-name="Path 22"
              strokeWidth={width}
              stroke={COLORS[color]}
            />
          </g>
        </svg>
      );

    case 'globe':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          data-test="globe"
        >
          <path
            id="ic_public_24px"
            d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-1 17.93A7.988 7.988 0 0 1 4 12a8.118 8.118 0 0 1 .21-1.79L9 15v1a2.006 2.006 0 0 0 2 2zm6.9-2.54A1.984 1.984 0 0 0 16 16h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2.006 2.006 0 0 0 2-2v-.41a7.982 7.982 0 0 1 2.9 12.8z"
            transform="translate(-2 -2)"
            fill={COLORS[color]}
          />
        </svg>
      );

    case 'calendar':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.51"
          height="19.813"
          viewBox="0 0 18.51 19.813"
          fill="none"
          data-test="calendar"
        >
          <path
            id="calender_event"
            d="M-1046.673-159.437a.327.327 0 0 1-.328-.326v-17.607a.327.327 0 0 1 .328-.326h2.281v-.978a.325.325 0 0 1 .326-.326h2.282a.326.326 0 0 1 .326.326v.978h7.174v-.978a.325.325 0 0 1 .326-.326h2.282a.326.326 0 0 1 .327.326v.978h2.283a.326.326 0 0 1 .326.326v17.608a.326.326 0 0 1-.326.326zm.326-.653h16.953v-13.368h-16.953zm0-14.02h16.953v-2.934h-1.956v.978a.326.326 0 0 1-.327.326h-2.282a.325.325 0 0 1-.326-.326v-.978h-7.174v.978a.326.326 0 0 1-.326.326h-2.282a.325.325 0 0 1-.326-.326v-.978h-1.956zm12.715-3.261v.978h1.63v-1.956h-1.63zm-10.108 0v.978h1.63v-1.956h-1.63zm9.13 15.325h-9.456v-9.454h12.389v9.456zm0-.653h2.281v-2.282h-2.281zm-2.936 0h2.282v-2.282h-2.282zm-2.933 0h2.281v-2.282h-2.281zm-2.936 0h2.282v-2.282h-2.282zm8.8-2.934h2.281v-2.282h-2.281zm-2.936 0h2.282v-2.282h-2.282zm-2.933 0h2.281v-2.282h-2.281zm-2.936 0h2.282v-2.282h-2.282zm5.869-2.935h2.282v-2.282h-2.282zm-2.933 0h2.281v-2.282h-2.281zm-2.936 0h2.282v-2.282h-2.282z"
            data-name="calender event"
            transform="translate(1047.126 179.125)"
            fill={COLORS[color]}
          />
        </svg>
      );

    case 'user':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.29"
          height="16.5"
          viewBox="0 0 22.29 16.5"
          fill="none"
          data-test="user"
        >
          <g id="user" transform="translate(.25 -27.25)">
            <g
              id="Group_1808"
              data-name="Group 1808"
              transform="translate(0 27.5)"
            >
              <path
                id="Path_2814"
                d="M156.158 39.671a1.843 1.843 0 0 0-.658 1.419v1.97a.434.434 0 1 0 .868 0v-1.97a.951.951 0 0 1 .342-.738 22.014 22.014 0 0 1 3.952-2.57.567.567 0 0 0 .316-.511v-2.776a.443.443 0 0 0-.193-.365.858.858 0 0 1-.377-.712v-2.876a2.14 2.14 0 0 1 2.123-2.152h.61a2.139 2.139 0 0 1 2.123 2.152v2.877a.858.858 0 0 1-.377.712.432.432 0 0 0-.193.365v2.77a.567.567 0 0 0 .316.511 21.855 21.855 0 0 1 3.952 2.57.965.965 0 0 1 .342.738v1.97a.434.434 0 1 0 .868 0v-1.97a1.85 1.85 0 0 0-.658-1.419 23.021 23.021 0 0 0-3.952-2.6V34.7a1.752 1.752 0 0 0 .57-1.294v-2.873a3.016 3.016 0 0 0-2.991-3.033h-.61a3.016 3.016 0 0 0-2.991 3.033v2.877a1.752 1.752 0 0 0 .57 1.294v2.366a23.3 23.3 0 0 0-3.952 2.601z"
                data-name="Path 2814"
                transform="translate(-148.382 -27.5)"
                fill={COLORS[color]}
              />
              <path
                id="Path_2815"
                d="M6.578 90.7h-.517a2.63 2.63 0 0 0-2.627 2.627v2.413a1.537 1.537 0 0 0 .485 1.121v1.9a19.618 19.618 0 0 0-3.329 2.156 1.612 1.612 0 0 0-.59 1.254v1.652a.453.453 0 0 0 .906 0v-1.652a.72.72 0 0 1 .261-.554 18.663 18.663 0 0 1 3.346-2.147.563.563 0 0 0 .316-.5v-2.328a.454.454 0 0 0-.2-.375.632.632 0 0 1-.284-.526v-2.414a1.725 1.725 0 0 1 1.721-1.721h.517A1.725 1.725 0 0 1 8.3 93.327v2.413a.632.632 0 0 1-.284.526.443.443 0 0 0-.2.375v1.909a.453.453 0 0 0 .906 0v-1.689a1.537 1.537 0 0 0 .488-1.121v-2.413A2.634 2.634 0 0 0 6.578 90.7z"
                data-name="Path 2815"
                transform="translate(0 -88.277)"
                fill={COLORS[color]}
              />
            </g>
          </g>
        </svg>
      );

    case 'clock':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          data-test="clock"
        >
          <g id="fast" transform="translate(-116.489 -58.245)">
            <g
              id="Group_1567"
              data-name="Group 1567"
              transform="translate(116.489 58.245)"
            >
              <g id="Group_1566" data-name="Group 1566">
                <path
                  id="Path_2812"
                  d="M124.489 58.245a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zm0 14.786a6.786 6.786 0 1 1 6.786-6.786 6.794 6.794 0 0 1-6.786 6.786z"
                  data-name="Path 2812"
                  transform="translate(-116.489 -58.245)"
                  fill={COLORS[color]}
                />
              </g>
            </g>
            <g
              id="Group_1569"
              data-name="Group 1569"
              transform="translate(123.554 62.467)"
            >
              <g id="Group_1568" data-name="Group 1568">
                <path
                  id="Path_2813"
                  d="M295.2 170.675v-4.118h-1.27v4.792l3.286 2.239.718-1.054z"
                  data-name="Path 2813"
                  transform="translate(-293.93 -166.557)"
                  fill={COLORS[color]}
                />
              </g>
            </g>
          </g>
        </svg>
      );

    case 'location':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.711"
          height="16.689"
          viewBox="0 0 11.711 16.689"
          fill="none"
          data-test="location"
        >
          <g
            id="location_icon"
            data-name="location icon"
            transform="translate(-7.95 .25)"
          >
            <path
              id="Path_23"
              d="M22.031 12a1.474 1.474 0 1 0 1.469 1.474A1.476 1.476 0 0 0 22.031 12zm0 2.527a1.053 1.053 0 1 1 1.053-1.053 1.054 1.054 0 0 1-1.053 1.053z"
              fill={COLORS[color]}
              stroke={COLORS[color]}
              data-name="Path 23"
              transform="translate(-8.164 -8.157)"
            />
            <path
              id="Path_24"
              d="M17.768 1.641a5.6 5.6 0 0 0-7.924 0 6.382 6.382 0 0 0-.527 7.875L13.806 16l4.483-6.474a6.385 6.385 0 0 0-.521-7.885zm.046 7.543l-4.009 5.789-4.015-5.8a5.767 5.767 0 0 1 .466-7.121 5.019 5.019 0 0 1 7.1 0 5.769 5.769 0 0 1 .459 7.132z"
              fill={COLORS[color]}
              stroke={COLORS[color]}
              data-name="Path 24"
            />
          </g>
        </svg>
      );

    case 'video':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14.706"
          height="17.555"
          viewBox="0 0 14.706 17.555"
        >
          <g id="webcam" transform="translate(-42.506 .25)">
            <g
              id="Group_1546"
              data-name="Group 1546"
              transform="translate(42.756)"
            >
              <g id="Group_1545" data-name="Group 1545">
                <path
                  id="Path_300"
                  d="M56.962 7.1a7.1 7.1 0 1 0-8.225 7.014v.742l-2.4.481a.553.553 0 0 0-.444.541v.622a.552.552 0 0 0 .552.552h6.836a.552.552 0 0 0 .552-.552v-.621a.553.553 0 0 0-.444-.541l-2.4-.481v-.742A7.114 7.114 0 0 0 56.962 7.1zm-3.682 8.761a.018.018 0 0 1 .014.017v.622a.018.018 0 0 1-.018.018h-6.835a.018.018 0 0 1-.018-.018v-.621a.018.018 0 0 1 .014-.017l2.593-.519h1.656zm-4.009-1.053v-.63a7.145 7.145 0 0 0 1.174 0v.63zm.587-1.139a6.569 6.569 0 1 1 6.57-6.569 6.576 6.576 0 0 1-6.569 6.572z"
                  stroke={COLORS[color]}
                  fill={COLORS[color]}
                  strokeWidth={0.5}
                  data-name="Path 300"
                  transform="translate(-42.756)"
                />
              </g>
            </g>
            <g
              id="Group_1548"
              data-name="Group 1548"
              transform="translate(46.679 3.94)"
            >
              <g id="Group_1547" data-name="Group 1547">
                <path
                  id="Path_301"
                  d="M165.652 119.716a3.18 3.18 0 1 0 3.18 3.18 3.184 3.184 0 0 0-3.18-3.18zm0 5.816a2.635 2.635 0 1 1 2.635-2.635 2.638 2.638 0 0 1-2.635 2.635z"
                  stroke={COLORS[color]}
                  strokeWidth={0.5}
                  data-name="Path 301"
                  transform="translate(-162.472 -119.716)"
                />
              </g>
            </g>
            <g
              id="Group_1550"
              data-name="Group 1550"
              transform="translate(48.444 5.697)"
            >
              <g id="Group_1549" data-name="Group 1549">
                <path
                  id="Path_302"
                  d="M198.091 153.921a1.415 1.415 0 1 0 1.415 1.415 1.417 1.417 0 0 0-1.415-1.415zm0 2.448a1.033 1.033 0 1 1 1.033-1.033 1.034 1.034 0 0 1-1.033 1.033z"
                  stroke={COLORS[color]}
                  strokeWidth={0.5}
                  data-name="Path 302"
                  transform="translate(-196.676 -153.921)"
                />
              </g>
            </g>
            <g
              id="Group_1552"
              data-name="Group 1552"
              transform="translate(49.261 1.71)"
            >
              <g id="Group_1551" data-name="Group 1551">
                <path
                  id="Path_303"
                  d="M231.479 42.756a.6.6 0 1 0 .6.6.6.6 0 0 0-.6-.6zm0 .815a.216.216 0 1 1 .216-.216.217.217 0 0 1-.216.216z"
                  fill={COLORS[color]}
                  data-name="Path 303"
                  transform="translate(-230.881 -42.756)"
                />
              </g>
            </g>
          </g>
        </svg>
      );

    case 'checkmark':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33.229"
          height="33.229"
          viewBox="0 0 33.229 33.229"
          fill="none"
          data-test="checkmark"
        >
          <g id="Check_Icon" data-name="Check Icon">
            <path id="Bounds" d="M0 0h33.229v33.229H0z" fill="none" />
            <path
              id="Icon"
              d="M11.153 20.276l-5.815-5.815L3.4 16.4l7.753 7.753L27.768 7.538 25.83 5.6z"
              fill={COLORS[color]}
              transform="translate(1.307 2.153)"
            />
          </g>
        </svg>
      );

    case 'description':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="10.684"
          viewBox="0 0 14 10.684"
          data-test="description"
        >
          <g
            id="description_icon"
            data-name="description icon"
            transform="translate(-18.323 -758)"
          >
            <path
              id="Path_297"
              d="M61.9 155h12.853a.754.754 0 0 1 0 1.464H61.9a.754.754 0 0 1 0-1.464zm0 0"
              fill={COLORS[color]}
              data-name="Path 297"
              transform="translate(-43.001 607.537)"
            />
            <path
              id="Path_298"
              d="M61.841 91h6.865c.285 0 .517.328.517.732s-.232.732-.517.732h-6.865c-.285 0-.517-.328-.517-.732s.232-.732.517-.732zm0 0"
              fill={COLORS[color]}
              data-name="Path 298"
              transform="translate(-43.001 676.221)"
            />
            <path
              id="Path_299"
              d="M74.753 217a.754.754 0 0 1 0 1.464H61.9a.754.754 0 0 1 0-1.464zm0 0"
              fill={COLORS[color]}
              data-name="Path 299"
              transform="translate(-43.001 541)"
            />
          </g>
        </svg>
      );

    case 'arrow-small-right':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7.229"
          height="11.689"
          viewBox="0 0 7.229 11.689"
          fill="none"
          data-test="arrow-small-right"
        >
          <path
            fill="none"
            stroke={COLORS[color]}
            strokeMiterlimit="10"
            strokeWidth="2"
            id="Path_23"
            d="M19.279 14.2l-5.172 5.107L9 14.2"
            data-name="Path 23"
            transform="rotate(-90 3.247 16.735)"
          />
        </svg>
      );

    case 'arrow-small-left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7.229"
          height="11.689"
          viewBox="0 0 7.229 11.689"
          fill="none"
          data-test="arrow-small-left"
        >
          <path
            fill="none"
            stroke={COLORS[color]}
            strokeMiterlimit="10"
            strokeWidth="2"
            id="Path_24"
            d="M19.279 14.2l-5.172 5.107L9 14.2"
            data-name="Path 24"
            transform="rotate(90 14.505 6.212)"
          />
        </svg>
      );
    default:
      return <></>;
  }
}

const Icon = memo(({ name, color, size, width }) => {
  return renderIcon(name, color, size, width);
});

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['dark', 'light', 'primary', 'white']),
  size: PropTypes.number,
  width: PropTypes.number,
};

Icon.defaultProps = {
  color: 'light',
  size: 1,
  width: 1,
};

export default Icon;
