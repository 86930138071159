import React, { Component } from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';

export const DataLayerContext = React.createContext({});

class DataLayerProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            selectedTime: '',
            ownerTimeOffset: null,
            ownTime: '',
        };
    }

    setClient = callbackID => {
        try {
            api
                .getUserInfo({
                    callback_id: callbackID,
                })
                .then(res => {
                    if (res.status === 200) {

                        res.data.data = {
                            utc_offset: 0,
                            full_name: res.data.full_name,
                            image: res.data.image,
                            description: res.data.description
                        };
                        
                        this.setState({
                            client: res.data && res.data.data,
                            ownerTimeOffset: res.data.data.utc_offset,
                            // ownerTimeOffset: 0,
                        });
                        document.title = `Sync.me - ${res.data.data.full_name ||
              'Call Assistant'}`;
                    }
                })
                .catch(error => {
                    console.error(error);
                    window.location.pathname = '/c';
                });
        } catch (e) {
            console.error(e);
            window.location.pathname = '/c';
        }
    };

    setClientSelectedTime = (selectedTime, day) => {
        let time = selectedTime;
        day === 'today' && (time = `${day} ${selectedTime}`);
        day === 'tomorrow' && (time = `${day} ${selectedTime}`);
        this.setState({ selectedTime: time });
    };

    setOwnTime = ownTime => {
        this.setState({ ownTime });
    };

    setOwnTimeOffset = timeOffset => {
        this.setState({ ownerTimeOffset: timeOffset });
    };

    render() {
        const { children } = this.props;

        return (
            <DataLayerContext.Provider
              value={{
                  ...this.state,
                  setClientSelectedTime: this.setClientSelectedTime,
                  setClient: this.setClient,
                  setOwnTime: this.setOwnTime,
                  setOwnTimeOffset: this.setOwnTimeOffset,
              }}
              >
              {children}
            </DataLayerContext.Provider>
        );
    }
}

DataLayerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DataLayerProvider;
