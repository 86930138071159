import React from 'react';

import PoweredBy from '../PoweredBy';
import styles from './styles.module.scss';

const Footer = () => {
  // const userID = window.location.pathname.slice(1, 9);
  // const bgGrey =
  //   userID === '8RrF0GLE'
  //     ? { backgroundColor: 'white', filter: 'invert(0%)' }
  //     : {};
      
  return (
    // <div className={styles.container}>
      <footer className={styles.footer} 
      // style={bgGrey}
      >
        <PoweredBy />
      </footer>
    // </div>
  );
};

export default Footer;
