/**
 * Changes breakpoints object
 * @param {string} activeBreakpoint selected breakpoint name
 * @param {Object} all entire breakpoints object
 * @returns {Object} changed breakpoints object
 */
export function setBreakpoint(activeBreakpoint, all) {
  const updatedScreenState = { ...all };
  Object.keys(all).forEach(breakpoint => {
    if (breakpoint === activeBreakpoint) {
      updatedScreenState[breakpoint] = true;
    } else {
      updatedScreenState[breakpoint] = false;
    }
  });
  return updatedScreenState;
}

/**
 * Defines active screen width using `innerWidth` window property value
 * @param {number} actualScreenWidth given window inner width
 * @param {Object} allScreenStates entire breakpoints object
 * @returns {Object} updated breakpoint values
 */
export default (actualScreenWidth, allScreenStates) => {
  switch (true) {
    case actualScreenWidth < 600:
      return setBreakpoint('xs', allScreenStates);
    case actualScreenWidth >= 600 && actualScreenWidth < 750:
      return setBreakpoint('sm', allScreenStates);
    case actualScreenWidth >= 750 && actualScreenWidth < 1060:
      return setBreakpoint('md', allScreenStates);
    case actualScreenWidth >= 1060 && actualScreenWidth < 1240:
      return setBreakpoint('lg', allScreenStates);
    case actualScreenWidth >= 1240 && actualScreenWidth < 1440:
      return setBreakpoint('xl', allScreenStates);
    default:
      return setBreakpoint('xxl', allScreenStates);
  }
};
