import React, { Component } from 'react';
import PropTypes from 'prop-types';

import defineActiveBreakpoint from 'utils/defineActiveBreakpoint';

export const LayoutContext = React.createContext('xs');

export default class LayoutProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xs: true,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      xxl: false,
    };
  }

  componentDidMount() {
    // Set initial screen width
    const initialScreenWidthEvent = {
      target: {
        innerWidth: window.innerWidth,
      },
    };
    this.handleWindowResize(initialScreenWidthEvent);
    // Set listeners
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillUnmount() {
    // Remove event listeners
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
    );
  }

  /**
   * Changes screen size state
   * @param {Object} e Event object
   */
  handleWindowResize = e => {
    this.setState(state => ({
      ...defineActiveBreakpoint(e.target.innerWidth, state),
    }));
  };

  /**
   * Changes screen size state using `orientationchange` event
   * @param {Object} e Event object
   */
  handleOrientationChange = e => {
    // Remove `resize` listener to avoid additional screensize
    // re-calculation/re-rendering
    window.removeEventListener('resize', this.handleWindowResize);

    const { innerHeight, innerWidth } = e.target;
    if (innerHeight > innerWidth) {
      // Set window size using opposite side
      this.handleWindowResize({ target: { innerWidth: innerHeight } });
    } else {
      this.handleWindowResize(e);
    }

    // Turn on `resize` listener back
    window.addEventListener('resize', this.handleWindowResize);
  };

  render() {
    const { children } = this.props;
    const { lg, xl, xxl } = this.state;

    const isMobile = !lg && !xl && !xxl;

    return (
      <LayoutContext.Provider value={{ ...this.state, isMobile }}>
        {children}
      </LayoutContext.Provider>
    );
  }
}

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
