import React, { memo } from 'react';

import Icon from '../Icon';
import styles from './styles.module.scss';

const BackButton = memo(({ onClick, onlyMobile, onlyDesktop }) => {
  return (
    <button
      className={[
        styles.container,
        onlyMobile && styles.onlyMobile,
        onlyDesktop && styles.onlyDesktop,
      ].join(' ')}
      onClick={onClick}
      type="button"
    >
      <Icon name="arrow-left" color="dark" size={1} width={1}/>
    </button>
  );
});

export default BackButton;
