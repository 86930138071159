import React from 'react';

import LayoutProvider, { LayoutContext } from 'providers/Layout';
import DataLayerProvider, { DataLayerContext } from 'providers/DataLayer';
import Router from 'Router';

const App = () => (
  <LayoutProvider>
    <LayoutContext.Consumer>
      {layout => (
        <DataLayerProvider>
          <DataLayerContext.Consumer>
            {data => <Router layout={layout} data={data} />}
          </DataLayerContext.Consumer>
        </DataLayerProvider>
      )}
    </LayoutContext.Consumer>
  </LayoutProvider>
);

export default App;
