import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAppLink } from 'utils/os';
import styles from './styles.module.scss';
import logoSrc from '../../../assets/images/logo.png';
import { Event } from 'Tracking';

const TextLogo = () => (
  <svg version="1.1" id="Layer_1"  x="0px" y="0px" width="632.419px" height="194.99px" viewBox="0 0 632.419 194.99">
  <g>
    <g>
      <path fill="#3792E4"
            d="M379.795,14.991c0-2.44,0.676-3.865,2.034-4.267c1.345-0.407,2.769-0.336,4.264,0.204
	       c1.481,0.549,2.978,1.423,4.464,2.639c1.488,1.22,2.504,2.104,3.048,2.639c0.544,7.047,0.269,16.656-0.812,28.845
	       c-1.084,12.188-1.628,27.355-1.628,45.497c1.08-4.326,2.708-9.478,4.877-15.438c2.162-5.955,4.675-11.985,7.515-18.077
	       c2.842-6.095,5.95-11.778,9.346-17.061c3.386-5.278,6.701-9.476,9.951-12.594c3.25-3.108,6.357-4.672,9.347-4.672
	       c2.969,0,5.682,2.306,8.12,6.912c0.542,1.354,1.015,4.125,1.423,8.319c0.407,4.204,0.676,9.279,0.816,15.235
	       c0.122,5.961,0.329,12.595,0.604,19.905c0.267,7.312,0.676,14.771,1.222,22.345c2.439-5.956,5.206-12.522,8.326-19.705
	       c3.115-7.169,6.429-13.67,9.955-19.492c3.515-5.823,7.31-10.363,11.377-13.612c4.056-3.249,8.39-3.919,12.996-2.033
	       c2.161,0.813,3.653,3.321,4.468,7.513c0.813,4.206,1.485,9.144,2.027,14.829c0.541,5.697,1.149,11.717,1.829,18.073
	       c0.676,6.377,1.962,12.131,3.865,17.269c1.889,5.147,4.598,9.279,8.122,12.389c3.517,3.116,8.259,4.266,14.217,3.456
	       c3.249,0,5.616,0.066,7.112,0.203c1.48,0.139,2.564,0.339,3.246,0.607c0.676,0.275,0.946,0.679,0.811,1.226
	       c-0.135,0.542-0.204,1.35-0.204,2.433c-10.024,5.143-18.207,6.846-24.577,5.081c-6.363-1.759-11.367-5.551-15.026-11.376
	       c-3.653-5.821-6.238-12.86-7.719-21.121c-1.489-8.261-2.509-16.452-3.044-24.582c-0.549-3.512-1.69-5.01-3.451-4.467
	       c-1.774,0.546-3.938,2.577-6.506,6.091c-2.569,3.524-5.349,7.929-8.326,13.209c-2.983,5.277-5.826,10.895-8.532,16.852
	       c-2.712,5.958-5.075,11.716-7.106,17.263c-2.038,5.554-3.6,10.094-4.673,13.616c-1.355,2.434-3.526,3.116-6.498,2.029
	       c-2.985-1.077-4.873-3.109-5.695-6.099c-1.082-4.323-1.69-9.676-1.819-16.044c-0.146-6.356-0.146-12.792,0-19.291
	       c0.129-6.506,0.334-12.799,0.605-18.897c0.268-6.089,0.268-11.298,0-15.636c-0.271-4.323-0.807-7.311-1.623-8.935
	       c-0.812-1.627-2.302-1.219-4.467,1.217c-4.609,7.045-8.875,15.029-12.797,23.97c-3.932,8.933-7.379,18.211-10.361,27.826
	       c-2.979,9.612-5.482,19.231-7.514,28.836c-2.033,9.622-3.321,18.352-3.856,26.206c-4.613,1.077-7.86,0.473-9.751-1.828
	       c-1.898-2.292-3.519-4.672-4.875-7.111c0.542-3.516,1.013-10.356,1.419-20.511c0.405-10.158,0.815-21.735,1.22-34.733
	       c0.404-12.997,0.813-26.265,1.217-39.811C379.181,36.792,379.521,25.013,379.795,14.991z"/>
       <path fill="#3792E4"
             d="M622.92,89.127c-0.14-0.669-0.615-0.744-1.422-0.2c-3.796,4.874-7.38,9.001-10.766,12.39
		c-3.387,3.383-7.923,7.512-13.61,12.391c-3.518,3.243-8.121,7.042-13.813,11.365c-5.687,4.34-11.512,7.792-17.463,10.362
		c-5.961,2.577-11.647,3.456-17.061,2.639c-5.423-0.812-9.479-4.732-12.187-11.778c11.639-8.39,21.727-17.198,30.263-26.407
		c8.529-9.2,15.363-20.714,20.512-34.531c3.516-12.447,3.184-20.853-1.012-25.182c-4.204-4.326-12.602-6.463-18.034-6.676
		c-2.791-0.108-5.539-0.112-9.384,0.918c-3.951,1.06-5.822,1.903-6.908,2.511c-5.404,3.015-10.161,7.105-14.222,12.386
		c-4.056,5.279-7.856,11.718-11.369,19.296c-5.151,14.62-7.858,26.545-8.131,35.747c-0.271,9.206,0.272,16.52,1.627,21.937
		c0.271,1.361,0.812,2.978,1.627,4.876c0.81,1.897,2.089,4.001,3.857,6.295c1.759,2.305,4.265,4.541,7.516,6.702
		c3.254,2.17,7.583,4.202,13.002,6.091c6.497,1.086,12.246,1.086,17.259,0c5.014-1.077,9.615-2.974,13.816-5.687
		c4.195-2.705,8.066-6.013,11.983-9.545c3.99-3.599,7.307-6.634,11.36-10.432l15.244-16.786c1.489-1.758,2.691-3.17,3.76-4.854
		c0.937-1.477,1.727-2.794,2.508-4.684c0.513-1.244,0.87-2.362,1.25-5.234C623.311,91.617,623.053,89.805,622.92,89.127z
		M538.02,86.286c2.573-7.989,5.746-15.239,9.545-21.738c3.793-6.499,7.722-11.324,12.119-15.097
		c4.234-3.634,7.996-5.615,13.478-3.423c1.941,0.777,2.858,2.428,3.175,3.353c0.917,2.68,0.987,3.916,0.706,7.867
		c-0.42,5.877-3.011,11.638-6.121,17.057c-3.115,5.417-6.769,10.356-10.963,14.824c-4.206,4.471-8.671,8.397-13.407,11.78
		c-4.743,3.39-8.875,6.029-12.386,7.922C534.165,101.793,535.44,94.267,538.02,86.286z"/>
    </g>
    <g>
      <g>
	<path fill="#2F2F30"
              d="M10.374,123.717c7.834,5.021,19.08,8.836,31.134,8.836c17.874,0,28.32-9.241,28.32-23.1
		 c0-12.451-7.229-19.882-25.51-26.714C22.225,74.706,8.566,63.055,8.566,44.174c0-21.089,17.477-36.76,43.787-36.76
		 c13.661,0,23.904,3.213,29.731,6.629L77.26,28.304c-4.216-2.611-13.256-6.424-25.507-6.424c-18.48,0-25.51,11.043-25.51,20.286
		 c0,12.652,8.235,18.879,26.915,26.11c22.897,9.039,34.346,19.885,34.346,39.772c0,20.889-15.266,39.168-47.202,39.168
		 c-13.056,0-27.32-4.018-34.55-8.84L10.374,123.717z"/>
	<path fill="#2F2F30"
              d="M97.996,47.789l21.094,57.447c2.41,6.426,4.819,14.061,6.429,19.885h0.398
		 c1.808-5.824,3.817-13.256,6.224-20.285l19.288-57.047h18.68l-26.517,69.298c-12.652,33.345-21.29,50.416-33.341,61.06
		 c-8.84,7.434-17.275,10.449-21.696,11.251l-4.418-14.863c4.418-1.208,10.245-4.017,15.465-8.637
		 c4.824-3.817,10.648-10.645,14.864-19.685c0.806-1.811,1.406-3.015,1.406-4.22c0-1.001-0.401-2.412-1.406-4.417L78.716,47.789
		 H97.996z"/>
	<path fill="#2F2F30"
              d="M176.547,74.101c0-10.242-0.204-18.277-0.805-26.312h15.666l1.006,15.866h0.4
		 c4.823-9.036,16.071-18.076,32.141-18.076c13.455,0,34.346,8.034,34.346,41.376v58.054h-17.675V88.966
		 c0-15.666-5.828-28.925-22.498-28.925c-11.449,0-20.491,8.235-23.702,18.079c-0.802,2.212-1.202,5.422-1.202,8.235v58.653
		 h-17.677V74.101z"/>
	<path fill="#2F2F30"
              d="M342.072,141.592c-4.624,2.21-14.866,5.625-27.922,5.625c-29.328,0-48.41-19.886-48.41-49.817
		 c0-29.925,20.488-51.821,52.222-51.821c10.447,0,19.687,2.611,24.508,5.221l-4.017,13.461c-4.22-2.211-10.848-4.621-20.491-4.621
		 c-22.297,0-34.342,16.67-34.342,36.96c0,22.495,14.458,36.358,33.742,36.358c10.046,0,16.672-2.411,21.694-4.622L342.072,141.592
		 z"/>
      </g>
      <polyline fill="#C30876" points="350.375,136.11 352.911,147.36 365.999,125.737 345.509,114.433 348.22,126.491 		"/>
    </g>
  </g>
</svg>

);

const PoweredBy = () => {
    const { t } = useTranslation();
    const link = getAppLink();
  
  const handleClickFooterPromotionBar = () => {
    Event('Missed call assistant', 'Click on footer promotion bar');
  };
  return (
    <a
      className={styles.container}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClickFooterPromotionBar}
    >
      <div className={styles.logo}>
        <img className={styles.logo__image} alt="Sync.me" src={logoSrc} />
      </div>
      <div className={styles.text}>
        <p className={styles.text__label}>{t('Powered by')}</p>
        <TextLogo />
      </div>
    </a>
  );
};

export default PoweredBy;
