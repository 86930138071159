import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Button = ({
  type,
  children,
  onClick,
  size,
  color,
  fontSize,
  textLeft,
  radiusBig,
  heightMid,
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={[
        styles.container,
        textLeft && styles.container_textLeft,
        radiusBig && styles.container_radiusBig,
        heightMid && styles.container_heightMid,
        styles[`button_${size}`],
        styles[`button_${color}`],
        styles[`button_${fontSize}`],
      ].join(' ')}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'light', 'white', 'whiteActive']),
  fontSize: PropTypes.oneOf(['fontSm', 'fontMd', 'fontLg']),
  textLeft: PropTypes.bool.isRequired,
  radiusBig: PropTypes.bool.isRequired,
  heightMid: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  type: 'button',
  size: 'lg',
  color: 'primary',
  onClick: null,
  fontSize: 'fontMd',
  textLeft: false,
  radiusBig: false,
  heightMid: false,
};

export default Button;
