import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Error404 from 'components/Error404';

const MainPage = lazy(() => import('pages/MainPage'));
const PickTimePage = lazy(() => import('pages/PickTimePage'));
const ConfirmPage = lazy(() => import('pages/ConfirmPage'));

/**
 * Router component
 * ================
 * Configures client-side routing
 * @param {Object} layout props from Layout provider
 * @param {Object} data props from DataLayer provider
 */
const Router = ({ layout, data }) => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path={'/c/:callbackID'}
        // path={'/8RrF0GLE'}
        // path={'/ptUgTL1A'}
        // path={'/h8ynDPg6'}
        render={() => (
          <Suspense fallback="">
            <MainPage layout={layout} data={data} />
          </Suspense>
        )}
      />
      <Route
        exact
        path={'/c/:callbackID/pick'}
        render={() => (
          <Suspense fallback="">
            <PickTimePage layout={layout} data={data} />
          </Suspense>
        )}
      />
      <Route
        exact
        path={'/c/:callbackID/confirm'}
        render={() => (
          <Suspense fallback="">
            <ConfirmPage layout={layout} data={data} />
          </Suspense>
        )}
      />
      <Route component={Error404} />
    </Switch>
  </BrowserRouter>
);

export default Router;
