export function getOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export function isIosX() {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  // Get the device pixel ratio
  const ratio = window.devicePixelRatio || 1;
  // Define the users device screen dimensions
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  };

  // iPhone X Detection
  return iOS && screen.width === 1125 && screen.height === 2436;
}

export function getAppLink() {

    const os = getOS();
    
    let link = '';

    if(os === 'Android'){
        link = 'https://play.google.com/store/apps/details?id=com.syncme.syncmeapp';
    }
    else if(os === 'iOS'){
        link = 'https://apps.apple.com/us/app/id340787494';
    }

    return link;
        
}
